<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#1f9979" />
    <div v-else>
      <!-- primary section -->
      <section class="primary">
        <b-container>
          <b-row>
            <b-col cols="12" md="7">
              <h1 v-html="$prismic.asHtml(this.primary_title)" class="primary__title"></h1>
              <p v-html="$prismic.asHtml(this.primary_text_1)" class="main-text main-text--semi"></p>
            </b-col>
            <b-col class="text-center" cols="12" md="5">
              <img v-lazy="this.primary_image.url" class="primary__image img-fluid" :alt="this.primary_image.alt" />
            </b-col>
          </b-row>
        </b-container>
        <b-container>
          <b-row>
            <b-col cols="12" md="7">
              <video-embed :params="{autoplay: 1}" :src="this.primary_video"></video-embed>
            </b-col>

          </b-row>
        </b-container>
        <b-container>
          <b-row>
            <b-col cols="12">
              <div v-html="$prismic.asHtml(this.primary_text_2)" class="main-text mt-5 mb-0"></div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- about section -->
      <section class="about">
        <b-container>
          <h2 v-html="$prismic.asHtml(this.about_title)" class="about__title"></h2>
          <p v-html="$prismic.asHtml(this.about_text)" class="main-text"></p>
          <b-row v-if="this.about_features_list.length > 0" class="about__box">
            <b-col v-for="(item, index) in this.about_features_list" :key="`${index}`" cols="12" lg="4">
              <div class="about__element">
                <img :src="item.about_icon.url" class="about__icon img-fluid" :alt="item.about_icon.alt" />
                <h3 v-html="$prismic.asHtml(item.about_subtitle)" class="about__subtitle"></h3>
                <div class="about__text" v-html="$prismic.asHtml(item.about_description)"></div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- info section -->
      <section v-if="$prismic.richTextAsPlain(this.info_title)" class="info">
        <b-container>
          <b-row>
            <b-col cols="12">
              <h2 v-html="$prismic.asHtml(this.info_title)" class="info__title"></h2>
              <div v-html="$prismic.asHtml(this.info_text)" class="main-text"></div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- quote section -->
      <section class="quote">
        <b-container>
          <h2 v-html="$prismic.asHtml(this.quote_title)" class="quote__title"></h2>
          <carousel v-if="this.quotes_list.length > 0" class="quote__carousel" :responsive="{0:{items:1}}" :nav="false" :dots="false">
            <template slot="prev"><span class="prev"><img src="@/assets/images/home_page/chevron_left.svg" class="img-fluid quote__prev" alt="icon" /></span></template>
            <div v-for="(item, index) in this.quotes_list" :key="`${index}`"  class="row">
              <div class="col-3">
                <img src="@/assets/images/for_business/quote_icon.svg" alt="icon" class="img-fluid quote__icon" />
              </div>
              <div class="col-9">
                <div class="main-text text-left" v-html="$prismic.asHtml(item.quote_text)"></div>
                <p class="main-text mb-0"><b>{{ $prismic.richTextAsPlain(item.quote_person) }}</b><br> {{ $prismic.richTextAsPlain(item.quote_position) }}</p>
              </div>
            </div>
            <template slot="next"><span class="next"><img src="@/assets/images/home_page/chevron_right.svg" class="img-fluid quote__next" alt="icon" /></span></template>
          </carousel>
        </b-container>
      </section>
      <!-- partners section -->
      <section class="partners">
        <b-container>
          <h2 v-html="$prismic.asHtml(this.partners_title)" class="partners__title"></h2>
          <div v-if="this.partners_list.length > 0" class="partners__box">
            <div v-for="(item, index) in this.partners_list" :key="`${index}`" class="partners__element">
              <img :src="item.partner_logo.url" class="img-fluid partners__company" :alt="item.partner_logo.alt" />
            </div>
          </div>
        </b-container>
      </section>
      <!-- aside section -->
      <AsideComponent />
    </div>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
import carousel from 'vue-owl-carousel'
export default {
  name: 'HouseBuildersPage',
  components: {
    Spinner,
    carousel,
    AsideComponent: () => import('@/components/AsideComponent/AsideComponent.vue')
  },
  data: () => {
    return {
      primary_title: null,
      primary_text_1: null,
      primary_text_2: null,
      primary_button_text: null,
      primary_button_url: null,
      primary_image: null,
      primary_video: null,
      about_title: null,
      about_text: null,
      about_features_list: [],
      info_title: null,
      info_text: null,
      quote_title: null,
      quotes_list: [],
      partners_title: null,
      partners_list: [],
      cmsContentPending: false
    }
  },
  methods: {
    async getHouseBuildersPageContent () {
      await this.$prismic.client.getSingle('housebuilderspage')
        .then((document) => {
          console.log(document)
          // primary/hero section
          this.primary_title = document.data.primary_title
          this.primary_text_1 = document.data.primary_text_1
          this.primary_text_2 = document.data.primary_text_2
          this.primary_video = document.data.housebuilders_video.embed_url
          this.primary_button_text = document.data.primary_button_text
          this.primary_button_url = document.data.primary_button_url
          this.primary_image = document.data.primary_image
          // about section
          this.about_title = document.data.about_title
          this.about_text = document.data.about_text
          this.about_features_list = document.data.about_features_list
          // info section
          this.info_title = document.data.info_title
          this.info_text = document.data.info_text
          // quote section
          this.quote_title = document.data.quote_title
          this.quotes_list = document.data.quotes_list
          // partners section
          this.partners_title = document.data.partners_title
          this.partners_list = document.data.partners_list
          // meta title
          window.document.querySelector('head title').textContent = this.$prismic.richTextAsPlain(document.data.meta_title)
          this.cmsContentPending = true
        })
    }
  },
  created () {
    this.getHouseBuildersPageContent()
  }
}
</script>

<style lang="scss" scoped>
  @import "./HouseBuildersPage.scss";
</style>
